
.header {
	$background-layer: 1;
	$content-layer: 2;

	height: calc(9/16 * 100vw - 110px);
	position: relative;
	display: flex;
	align-items: center;

	.bg {
		position: fixed;
			top: 0;
			left: 0;
			
		z-index: $background-layer;

		width: 100%;
		//height: 100vh;
		
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	video {
		width: 100vw;
	}

	.content {
		position: relative;
		z-index: $content-layer;
	}
	
	h1 {
		line-height: 1;
	}

	@include at-most($screen-md-max) {
		.bg {
			height: 0;
			padding-top: calc(9/16 * 100%)
		}

		video {
			display: none;
		}
	}

	@include at-most($screen-sm-max) {
		height: auto;
		padding: 70px 0;
		margin-top: 45px;

		.bg {
			padding-top: calc(445/750 * 100%);
			top: 45px;
		}
	}

	@include at-most($screen-xs-max) {
		padding: calc(40/320 * 100%) 0px;
		display: block;
		
		blockquote {
			margin: 25px 0 30px;
		}

		h3 {
			text-transform: none;
		}

		.content {
			max-width: 220/320 * 100%;
			margin: 0;
		}

		.bg {
			position: absolute;
			height: 100%;
			//background-attachment: fixed;
			top: 0;
		}

		/*video {
			width: 200%;
			transform: translate(-21%, 0);
		}/**/
	}
}