
.contact {
	$background-layer: 1;
	$content-layer: 2;

	height: 650px;
	position: relative;
	display: flex;
	align-items: center;

	.bg {
		position: fixed;
			top: 0;
			left: 0;
			
		z-index: $background-layer;

		width: 100%;
		//height: 100vh;
		
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	video {
		position: absolute;
			top: 0;
			left: 50%;
		
		transform: translate(-50%, 0);
		width: 1920px;
	}

	.content {
		position: relative;
		z-index: $content-layer;
		text-align: center;
	}

	h1 {
		line-height: 1;
		margin-bottom: 0;
	}

	.phone {
		display: block;
		@extend %title2;
		text-decoration: none;
		cursor: pointer;
		color: $color-text-primary;

		margin-top: 40px;
		//font-size: fs(30px);
		font-weight: $font-weight-bold;
	}

	@include at-most($screen-lg-max) {
		height: 550px;

		video {
			width: $screen-lg-max;
		}
	}

	@include at-most($screen-md-max) {
		height: 400px;

		.bg {
			position: absolute;
			background-attachment: fixed;
			background-size: cover;
			height: 100%;
			background-position: left 45% center;
		}

		video {
			display: none;
			//width: $screen-md-max;
		}
	} 

	@include at-most($screen-sm-max) {
		text-align: center;
		
		.bg {
			background-image: url(../img/contact-bg-mobile.jpg) !important;
			background-position: center center;

			height: 100%;
		}

		.phone {
			color: $color-accent-primary;
		}

		// video {
		// 	width: 1400px;
		// 	left: 56%;
		// }
	}

	@include at-most($screen-xs-max){
		height: auto;
		padding: 70px 0;

		h1 {
			font-size: fs(30px);
			line-height: 1.4;
		}

		.bg {
			top: 0;
		}

		// video {
		// 	right: 0;
		// 	left: auto;

		// 	transform: none;
		// }

		.phone {
			font-size: fs(25px);
			margin-top: 15px;
		}
	}
}