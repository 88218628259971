.pannel {
	background: $color-background-dark no-repeat center center;
	background-size: cover;
	padding: 125px 0;
	height: 100vh;
	width: 100%;

	position: fixed;
		top: 0;
		left: 0;

	z-index: 30;
	color: $color-text-faded;
	overflow: auto;

	transform: translate(-100%, 0);
	transition: transform 0.6s $easeInOutCubic;

	.back-btn {
		@extend %title;
		font-weight: $font-weight-medium;
		color: $color-text-secondary;
		cursor: pointer;
		font-size: fs(14px);
		transition: color 0.3s;

		.icon-arrow {
			display: inline-block;
			position: relative;
			margin-right: 20px;
			transform: rotate(180deg);
		}

		&, &:last-child {
			margin-bottom: 125px;
		}

		&:hover {
			color: $color-text-accent;
		}
	}

	.number {
		@extend %title;
		line-height: 1;
		color: $color-text-accent;
		font-weight: $font-weight-normal;
		position: relative;
		letter-spacing: normal;
		font-size: fs(130px);
		z-index: 2;
	}

	.line {
		position: absolute;
			top: 50%;
			left: 60px;
		
		height: 1px;
		width: 125px;
		background: $color-text-faded;
		transform: translate(0, -50%) translateZ(1);
	}

	h2 {
		font-weight: $font-weight-medium;
		color: $color-text-secondary;
		margin-bottom: 70px;
	}

	p {
		line-height: 30px;
	}

	&.active {
		transform: translate(0, 0);
	}

	@include at-most($screen-lg-max) {
		padding: 90px 0;
		
		.back-btn {
			&, &:last-child {
				margin-bottom: 50px;
			}
		}

		h2 {
			margin-bottom: 50px;
		}

		.number {
			font-size: fs(100px);
		}

		.line {
			left: 50px;
			width: 90px;
		}
	}

	@include at-most($screen-md-max) {
		h2 {
			margin-bottom: 40px;
		}
	}

	@include at-most($screen-sm-max) {
		padding: calc(40/320 * 100%) 0;
		//overflow-x: hidden;
		//overflow-y: scroll;
	}
}