
.prefooter {
	position: relative;
	z-index: 2;
	//height: 300px;
	background: $gray8;
	color: $color-text-faded;

	.info-row {
		display: flex;
		align-items: stretch;

		> div {
			padding: 75px $grid-gutter-width/2;
		}

		.logo {
			width: 140px;
		}

		.certif {
			width: 150px;
		}

		.address {
			h3 {
				color: $color-text-secondary;
				margin-bottom: 25px;
			}
		}

		.phone {
			background: $gray9;
			display: flex;
			align-items: center;
			justify-content: center;

			font-size: fs(18px);
			color: $color-text-secondary;
			font-weight: $font-weight-medium;
		}
	}

	@include at-most($screen-xs-max) {
		.info-row {
			display: block;
			text-align: center;
			padding: 20px 0;

			> div {
				padding: 20px $grid-gutter-width/2;
			}

			.logo {
				width: calc(110/320 * 100%);
			}

			.phone {
				display: none;
			}

			.address h3 {
				margin-bottom: 10px;
			}

			.address + .address {
				position: relative;

				&:before {
					content: ' ';
					display: block;
					position: absolute;
						top: 0;
						left: $grid-gutter-width/2;
					
					width: calc(100% - #{$grid-gutter-width});
					border-top: 1px solid #282b30;
				}
			}
		}
	}
}

footer {
	position: relative;
	z-index: 2;
	background: $gray8;
	border-top: 1px solid #202024;
	padding: 25px 0;

	.flex-row {
		display: flex;
		align-items: center;
	}

	.copy {
		margin-left: auto;
		@extend %title;
		color: $color-text-faded;
		font-size: fs(12px);
	}

	.footer-nav {
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		.nav-item {
			display: inline-block;
			vertical-align: middle;

			& + .nav-item {
				margin-left: 30px;
			}
		}

		a {
			@extend %title;
			color: $color-text-faded;
			font-size: fs(14px);
			transition: color 0.3s;

			&:hover {
				text-decoration: none;
				color: $color-text-accent;
			}
		}
	}

	@include at-most($screen-xs-max) {
		display: none;
	}
}