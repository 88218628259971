
%base_button {
	display: inline-block;
	vertical-align: middle;
	border: none;
	outline: none;

	background: $color-accent-primary;
	color: $color-text-secondary;
	text-transform: uppercase;
	font-size: fs(16px);
	padding: 12px 35px;
	text-align: center;
	min-width: 150px;

	transition: background 0.3s;

	i {
		margin-right: 15px;
	}

	&:hover, &:focus {
		color: $color-text-secondary;
		text-decoration: none;
		background: mix($black, $color-accent-primary, 10%);
	}

	@include at-most($screen-xs-max) {
		padding: 12px 35px;
		font-size: fs(14px);
	}
}

%button_play {
	i {
		@include triangle((
			width: 8px,
			side: 'right',
			color: $white
		));

		border-left-width: 12px;

		display: inline-block;
		vertical-align: -10%;
		margin-right: 15px;
	}
}

[class^=button] {
	@extend %base_button;

	&[class*=-play] {
		@extend %button_play;
	}
}