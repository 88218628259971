
.faq {
	padding-bottom: 100px;
	position: relative;
	z-index: $faq-layer;
	background: $color-background-light;

	.intro {
		text-align: center;
		padding: 40px 0;

		h2 {
			margin-bottom: 15px;
		}
	}

	.question-block {
		.question {
			display: flex;
			align-items: center;
			width: 100%;
			
			font-weight: $font-weight-bold;
			cursor: pointer;
			padding: 30px 0;
			transition: color 0.3s;

			.question-no {
				margin-right: 20px;
			}

			i {
				position: relative;
				transition: transform 0.3s;
				margin-left: auto;
			}

			&:hover {
				color: $color-text-accent;
			}
		}

		.answer {
			display: none;
			width: 90%;
			padding-bottom: 30px;
			padding-left: 35px;
		}

		& + .question-block {
			border-top: 1px solid #e2e3e5;
		}

		&.opened {
			.question i {
				transform: rotate(180deg);
			}
		}
	}

	.btns-row {
		margin-top: 100px;
		text-align: center;
	}

	@include at-most($screen-xs-max) {
		padding-bottom: 40px;

		.intro {
			text-align: left;

			h2 {
				margin-bottom: 5px;
			}

			strong {
				display: block;
			}
		}
		
		.question-block {
			.question {
				display: block;
				padding-right: 30px;
				position: relative;

				.question-no {
					margin-right: 10px;
				}

				i {
					position: absolute;
						top: 50%;
						right: 0;

					transform: translate(0, -50%);
				}
			}

			.answer {
				padding-left: 27px;
			}
			
			&.opened {
				.question i {
					transform: translate(0, -50%) rotate(180deg);
				}
			}
		}

		.intro {
			padding-bottom: 0;
		}

		.btns-row {
			margin-top: 10px;
		}
	}
}