
.testimony {
	position: relative;
	z-index: $testimony-layer;
	padding: 150px 0;
	text-align: center;
	
	background: $color-background-dark url(../img/testimony-bg.jpg) center center no-repeat;
	background-size: cover;

	blockquote {
		color: $color-text-secondary;
		font-style: italic;
		margin: 50px 0 0 0;
		font-size: fs(18px);
		letter-spacing: letter-spacing(100);

		q:before, q:after {
			display: none;
		}

		cite {
			display: block;
			margin-top: 30px;
			color: $color-text-faded;
		}
	}

	@include at-most($screen-sm-max) {
		padding: 90px 0;
	}

	@include at-most($screen-xs-max){
		padding: 50px 0;
		font-size: fs(15px);

		cite {
			font-size: 0.9em;
		}
	}
}