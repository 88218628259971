
%title {
	font-family: $font-family-title;
	text-transform: uppercase;
	font-weight: $font-weight-medium;
	line-height: 1.4;
	letter-spacing: letter-spacing(100);
	margin: 0;
	
	strong {
		color: $color-text-accent;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

%title1 {
	@extend %title;
	font-weight: $font-weight-black;
	font-size: $font-size-title1-xl;
	@include responsive-fontsizes((
		$screen-lg-max: $font-size-title1-lg,
		$screen-md-max: $font-size-title1-md,
		$screen-sm-max: $font-size-title1-sm,
		$screen-xs-max: $font-size-title1-xs,
	));
	
	strong {
		font-weight: $font-weight-black;
	}
}

%title2 {
	@extend %title;
	font-weight: $font-weight-black;
	font-size: $font-size-title2-xl;
	@include responsive-fontsizes((
		$screen-lg-max: $font-size-title2-lg,
		$screen-md-max: $font-size-title2-md,
		$screen-sm-max: $font-size-title2-sm,
		$screen-xs-max: $font-size-title2-xs,
	));
}

%title3 {
	@extend %title;
	font-size: $font-size-title3-xl;
	@include responsive-fontsizes((
		$screen-lg-max: $font-size-title3-lg,
		$screen-md-max: $font-size-title3-md,
		$screen-sm-max: $font-size-title3-sm,
		$screen-xs-max: $font-size-title3-xs,
	));
}

%text {
	font-family: $font-family-text;
	font-size: $font-size-text-xl;
	color: $color-text-primary;
	@include responsive-fontsizes((
		$screen-lg-max: $font-size-text-lg,
		$screen-md-max: $font-size-text-md,
		$screen-sm-max: $font-size-text-sm,
		$screen-xs-max: $font-size-text-xs,
	));
}


h1 {
	@extend %title1;
}

h2 {
	@extend %title2;
}

h3 {
	@extend %title3;
}

body {
	@extend %text;
}

p {
	margin-top: 0;
	margin-bottom: 1em;

	&:last-child {
		margin-bottom: 0;
	}
}

blockquote {
	margin: 1.5em 0;
	font-style: italic;
	font-size: $font-size-blockquote-xl;
	@include responsive-fontsizes((
		$screen-lg-max: $font-size-blockquote-lg,
		$screen-md-max: $font-size-blockquote-md,
		$screen-sm-max: $font-size-blockquote-sm,
		$screen-xs-max: $font-size-blockquote-xs,
	));

	q:after {
		content: ' »';
	}

	q:before {
		content: '« ';	
	}
}

strong {
	font-weight: $font-weight-bold;
}

em {
	font-style: italic;
}