
$screen-xl-min: 1500px;

$blue66: #00aeef;

$gray48: #6f727b;
$gray12: #202024;
$gray10: #1b1b21;
$gray9: #1a1a1d;
$gray8: #17171a;

$black: #000;
$white: #fff;


$color-accent-primary: $blue66;

$color-text-primary: $gray10;
$color-text-secondary: $white;
$color-text-faded: $gray48;
$color-text-accent: $color-accent-primary;

$color-background-dark: $gray10;
$color-background-light: $white;

$font-family-title: 'Roboto', Helvetica, Arial, sans-serif;
$font-family-text: 'Roboto', Helvetica, Arial, sans-serif;

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

$font-size-title1-xl: fs(100px);
$font-size-title1-lg: $font-size-title1-xl * 0.8;
$font-size-title1-md: $font-size-title1-xl * 0.8;
$font-size-title1-sm: $font-size-title1-xl * 0.66;
$font-size-title1-xs: fs(45px);

$font-size-title2-xl: fs(50px);
$font-size-title2-lg: $font-size-title2-xl;
$font-size-title2-md: $font-size-title2-xl * 0.8;
$font-size-title2-sm: $font-size-title2-xl * 0.66;
$font-size-title2-xs: $font-size-title2-xl * 0.5;

$font-size-title3-xl: fs(20px);
$font-size-title3-lg: $font-size-title3-xl * 0.8;
$font-size-title3-md: $font-size-title3-xl * 0.8;
$font-size-title3-sm: $font-size-title3-xl * 0.8;
$font-size-title3-xs: $font-size-title3-xl * 0.75;

$font-size-blockquote-xl: fs(30px);
$font-size-blockquote-lg: $font-size-blockquote-xl * 0.8;
$font-size-blockquote-md: $font-size-blockquote-xl * 0.8;
$font-size-blockquote-sm: $font-size-blockquote-xl * 0.8;
$font-size-blockquote-xs: fs(14px);

$font-size-text-xl: fs(16px);
$font-size-text-lg: fs(16px);
$font-size-text-md: fs(14px);
$font-size-text-sm: fs(14px);
$font-size-text-xs: fs(14px);


$main-content-layer: 1;
$prevention-layer: 2;
$faq-layer: 2;
$testimony-layer: 2;
$main-nav-layer: 3;
	$subnav-layer: 1;
	$nav-item-layer: 2;