
.prevention {
	position: relative;
	z-index: $prevention-layer;
	background: #1b1a1f;
	padding-top: 1850/1920 * 100%;

	.denis {
		position: absolute;
			bottom: 0;
			left: 50%;
		
		width: 100%;
		transform: translate(-50%, 0);

		.front-snow {
			position: absolute;
				bottom: calc(100/1920 * 100vw);
				left: 0;
			width: 100%;
			transform: translate(12px, 0);

			z-index: 2;
		}

		.back-snow {
			position: absolute;
				bottom: calc(50/1920 * 100vw);
				left: 0;
			
			width: 100%;
			transform: translate(0, 0);
		}
	}

	.steps {
		padding: 70px 0;
		position: absolute;
			top: 0;
			left: 0;

		z-index: 2;

		width: 100%;
		height: 100%;
	}

	.step {
		margin-top: 90/1920 * 100vw;
		width: 380px;
		position: relative;
		cursor: pointer;

		$box-layer: 1;
		$line-layer: 2;
		$text-layer: 3;

		.number, .title, .cta {
			position: relative;
			z-index: $text-layer;
		}

		.number {
			@extend %title1;
			line-height: 1;
			color: $color-text-accent;
			font-weight: $font-weight-normal;
			letter-spacing: normal;
		}

		.title {
			@extend %title;
			color: $color-text-secondary;
		}

		.cta {
			@extend %title;
			color: $color-text-faded;
			position: absolute;
				top: 40px;
				right: 0;
			
			transform: translate(0, 10px);
			opacity: 0;

			transition: transform 0.3s $easeInOutCubic 0.3s, opacity 0.3s $easeInOutCubic 0.3s;
		}

		.line {
			width: calc(100% - 30px);
			height: 1px;

			position: absolute;
				top: 60px;
				right: 0;

			background: $color-text-faded;
			transform-origin: center left;
			transform: scale(0.37, 1);
			transition: transform 0.3s $easeInOutCubic 0.2s;
			z-index: $line-layer;
		}

		.box {
			z-index: $box-layer;
			position: absolute;
				top: -40px;
				left: 40px;

			width: 300px;
			height: 175px;
			overflow: hidden;

			&:after {
				content: ' ';
				position: absolute;
					top: 0;
					left: 0;

				width: 100%;
				height: 100%;
				background: darken($gray8, 2.5%);
				transform: translate(-100%, 0);

				transition: transform 0.3s $easeInOutCubic;
			}
		}

		.plus {
			z-index: $text-layer;
			position: absolute;
				bottom: 6px;
				right: 0;
			
			width: 10px;
			height: 10px;

			transform: translate(-180px, 0);
			transition: transform 0.3s $easeInOutCubic 0.2s;

			&:after, &:before {
				content: ' ';
				position: absolute;
					top: 0;
					left: 50%;
				
				width: 1px;
				height: 100%;
				background: $white;
				transform: translate(-50%, 0);

				transition: transform 0.3s $easeInOutCubic;
			}

			&:after {
				width: 100%;
				height: 1px;
				left: 0;
				top: 50%;
				transform: translate(0, -50%);
			}
		}

		&:hover, &.active {
			.line {
				transform: scale(1, 1);
			}

			.cta {
				opacity: 1;
				transform: translate(0, 0);
			}

			.plus {
				transform: translate(0, 0);

				&:before, &:after {
					background: $color-text-faded;
				}

				&:before {
					transform: translate(-50%, -3px) rotate(-45deg);
				}

				&:after {
					transform: translate(0, 3px) rotate(-45deg);
				}
			}

			.box:after {
				transform: translate(0, 0);
			}
		}

		&:nth-child(even) {
			margin-left: calc(9/12 * 100%);
		}
	}

	@include at-most($screen-lg-max) {
		.steps {
			padding: 50px 0;
		}

		.step {
			margin-top: 30/1200 * 100vw;
			width: 300px;

			.line {
				transform: scale(0.45, 1);
				top: 50px;
			}

			.cta {
				top: 30px;
			}

			.plus {
				transform: translate(-100px, 0);
			}

			.box {
				width: 220px;
				height: 130px;
				top: -10px;
			}

			&:nth-child(odd) {
				margin-left: calc(1/12 * 100%);
			}	

			&:nth-child(even) {
				margin-left: calc(8/12 * 100%);
			}
		}
	}

	@include at-most($screen-md-max) {
		.step {
			width: 240px;

			.line {
				transform: scale(0.53, 1);
			}

			.plus {
				bottom: 4px;
				transform: translate(-60px, 0);
			}

			.box {
				width: 180px;
				height: 130px;
			}
		}
	}

	@include at-most($screen-sm-max) {
		padding-top: 105%;

		.step {
			&:nth-child(odd) {
				margin-left: 0;
			}
		}
	}

	@include at-most($screen-xs-max) {
		padding-top: 0;

		.denis {
			position: absolute;
				left: auto;
				right: 0;

			height: 100%;
			width: 100%;
			transform: none;
			text-align: right;

			img {
				max-height: 100%;
			}

			.front-snow, .back-snow {
				display: none;
			}
		}

		.steps {
			position: relative;
				top: auto;
				left: auto;
			
			height: auto;
		}

		.step {

			.cta {
				display: none;
			}

			.box {
				display: none;
			}

			.line {
				top: 17px;
				right: auto;
				left: $grid-gutter-width/2;
				width: 50px;
				transform: scale(1, 1);
			}

			& + .step {
				margin-top: 50px;
			}

			&:nth-child(odd), &:nth-child(even) {
				margin-left: 0;
			}

			&:hover {
				.plus {
					transform: translate(-60px, 0);

					&:after, &:before {
						content: ' ';
						position: absolute;
							top: 0;
							left: 50%;
						
						width: 1px;
						height: 100%;
						background: $white;
						transform: translate(-50%, 0);

						transition: transform 0.3s $easeInOutCubic;
					}

					&:after {
						width: 100%;
						height: 1px;
						left: 0;
						top: 50%;
						transform: translate(0, -50%);
					}
				}
			}
		}
	}
}