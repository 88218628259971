
.main-nav {
	background: $gray8;
	position: relative;
	z-index: $main-nav-layer;
	display: flex;
	align-items: stretch;
	//justify-content: flex-end;

	.logo {
		display: block;
		height: 150px;
		width: 250px;
		background: $gray9;

		position: absolute;
			top: 0;
			left: 0;
		
		text-align: center;

		img {
			width: 145px;
			margin-top: 25px;
		}
	}

	.nav-list {
		margin: 0 0 0 auto;
		padding-left: 0;
		list-style-type: none;
		font-size: 0;
	}

	.nav-item {
		display: inline-block;
		vertical-align: middle;
		position: relative;

		> a {
			position: relative;
			z-index: $nav-item-layer;

			font-size: fs(16px);
			
			height: 110px;
			line-height: 110px;
			padding: 0 30px;
			display: block;
			text-transform: uppercase;
			color: $color-text-faded;
			font-weight: $font-weight-medium;
			background: $gray8;

			transition: color 0.3s;
		}

		&:hover, &.active {
			> a {
				text-decoration: none;
				color: $color-text-accent;
			}
		}

		&:hover {
			.subnav {
				transform: translate(0, 0);
			}
		}
	}

	.subnav {
		position: absolute;
			top: 100%;
			right: 0;

		padding-left: 0;
		transition: transform 0.3s $easeInOutCubic;
		transform: translate(0, -100%);
	}

	.subnav-item {
		> a {
			height: 80px;
			line-height: 80px;
			min-width: 350px;
			text-align: center;
			background: $gray9;
			transition: color 0.3s, background 0.3s;
		}

		&:hover, &.active {
			> a {
				background: $gray10;
			}
		}
	}

	.phone {
		display: flex;
		background: $gray9;
		padding: 0 45px;
		align-items: center;
		color: $color-text-secondary;

		strong {
			font-weight: $font-weight-medium;
		}
	}

	@include at-most($screen-sm-max){
		position: fixed;
			top: 0;
			left: 0;
		width: 100%;
		padding: 0 $grid-gutter-width/2;
		z-index: 30;

		.logo {
			position: relative;
				top: auto;
				left: auto;
			
			height: auto;
			width: auto;
			display: flex;
			align-items: center;

			img {
				width: 40px;
				margin-top: 0;
			}
		}

		.nav-item {
			> a {
				height: 45px;
				line-height: 45px;
				padding: 0 8px;
				font-size: fs(12px);
			}
		}

		.hamburger {
			margin-left: 8px;
			
			> a {
				position: relative;
				
				height: 3px;
				width: 20px;
				padding: 0;
				background: $white;
				font-size: 0;

				&:before, &:after {
					content: ' ';
					background: $white;
					position: absolute;
						top: -6px;
						left: 0;
					
					width: 100%;
					height: 3px;
				}

				&:after {
					top: auto;
					bottom: -6px;
				}
			}

			.subnav {
				position: absolute;
					top: 22.5px;
					right: -$grid-gutter-width/2;

				height: calc(100vh - 45px);
				background: $gray9;
				transform: translate(100%, 0);
			}

			&:hover {
				.subnav {
					transform: translate(100%, 0);
				}
			}

			&.active {
				.subnav {
					transform: translate(0, 0);
				}
			}
		}
		
		.subnav-item > a {
			height: 60px;
			line-height: 60px;
		}

		.phone {
			display: none;
		}
	}
}