html {
	font-size: 100%;
}

body {
	background: $gray9;
	//overflow-x: hidden;

	&.fixed {
		overflow: hidden;
	}

	@include at-most($screen-sm-max) {
		//padding-top: 45px;
	}
}

.page-wrap {
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	-webkit-overflow-scrolling: touch;
}

img {
	max-width: 100%;
}